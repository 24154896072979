import axios from 'axios'
import store from "@/store";
import router from '@/router'

const instance = axios.create({
    baseURL: process.env.VUE_APP_ROOT_API+'/admin/',
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }
});

instance.interceptors.request.use(config => {

    const access_token = localStorage.getItem(process.env.VUE_APP_ACCESS_TOKEN_KEY)

    if (access_token) {
        config.headers.Authorization = 'Bearer ' + access_token
    }

    if (config.method === 'delete') {
        if (!confirm('Are you sure you want to delete this item?')) {
            return Promise.reject(new Error("Delete Canceled"));
        }
    }

    store.state.errors = []
    // store.dispatch('SET_LOADING', true);
    store.state.messages = []

    return config
}, error => {
    return Promise.reject({error});
})


instance.interceptors.response.use(response => {

    // store.dispatch('SET_LOADING', false);
    store.state.errors = []

    if(response.data.message){
        store.dispatch('PUSH_MESSAGE',{
            type:'success',
            text:response.data.message
        })
    }

    return response

}, error => {

    store.state.loading = false;
    const status = error.response ? error.response.status : null

    store.state.errors = error.response.data.errors ? error.response.data.errors : []

    if(error.response) {
        store.dispatch("PUSH_MESSAGE", {
            type: 'danger',
            text: error.response.data.message
        })
    }

    if(status === 401){
        localStorage.removeItem( process.env.VUE_APP_ACCESS_TOKEN_KEY );
        return router.push('/auth/login')
    }

    return Promise.reject(error);
})

export default instance
