import {createRouter, createWebHistory} from 'vue-router'
import store from "@/store";
import Dashboard from '@/layouts/Dashboard.vue'
import Auth from '@/layouts/Auth.vue'

const routes = [
	{
		path: '/',
		component: Dashboard,
		meta: {
			requires_auth: true
		},
		children: [
			{
				path: '',
				name: 'home',
				component: () => import('@/views/dashboard/Index.vue'),
			},
			{
				path: 'playlist',
				children:[
					{
						path: '',
						name: 'playlist.index',
						component: () => import('@/views/playlist/Index.vue'),
					},
					{
						path: ':playlist_id',
						children:[
							{
								path: '',
								name: 'playlist.view',
								component: () => import('@/views/playlist/View.vue'),
							},
						]
					}
				]
			},
			{
				path: 'medley',
				children:[
					{
						path: '',
						name: 'medley.index',
						component: () => import('@/views/medley/Index.vue'),
					},
				]
			},
			{
				path: 'stream',
				children:[
					{
						path: '',
						name: 'stream.index',
						component: () => import('@/views/player/Stream.vue'),
					},
				]
			},
			{
				path: 'eternal',
				children:[
					{
						path: '',
						name: 'eternal.index',
						component: () => import('@/views/eternal/Index.vue'),
					},
				]
			},
			{
				path: 'song',
				children:[
					{
						path: '',
						name: 'song.index',
						component: () => import('@/views/song/Index.vue'),
					},
					{
						path: 'create',
						name: 'song.create',
						component: () => import('@/views/song/Create.vue'),
					},
					{
						path: ':song_id',
						children:[
							{
								path: '',
								name: 'song.view',
								component: () => import('@/views/song/View.vue'),
							},
						]
					}
				]
			},
		]
	},
	{
		path: '/player',
		component: Auth,
		children: [
			{
				path:'',
				component: () => import('@/views/player/Index.vue'),
			}
		]
	},
	{
		path: '/auth',
		component: Auth,
		children: [
			{
				path: 'login',
				component: () => import('@/views/auth/Login.vue'),
			},
			{
				path: 'reset-password',
				component: () => import('@/views/auth/EmailResetLink.vue'),
			},
			{
				path: 'change-password',
				component: () => import('@/views/auth/ChangePassword.vue'),
			},
		]
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

router.afterEach(() => {
	store.dispatch('SET_LOADING', false)
	store.state.show_sidebar = false;
})

router.beforeEach((to,from, next) => {

	const requires_auth = to.matched.some(record => record.meta.requires_auth)
	const loggedIn = localStorage.getItem(process.env.VUE_APP_ACCESS_TOKEN_KEY)

	store.dispatch('EMPTY_MESSAGES')

	if(requires_auth && !loggedIn){
		next('/auth/login');
	}else if(to.path == '/auth/login' && loggedIn){
		next('/');
	}else if(to.path == '/auth/register' && loggedIn){
		next('/')
	}else{
		next();
	}
})

export default router
