<template>
	<Modal name="player_modal" title="BASHVIBE Player">
		<div class="block-content pb-4 space-y-5">
			<div class="cover bg-white position-relative" style="height: 250px;">
				<div class="info position-absolute bottom-0 start-0 p-3" v-if="current_song.name">
					<h3 class="mb-0 text-body-secondary">{{ current_song.name }}</h3>
					<p class="mb-0">{{ current_song.artist.name }}</p>
				</div>
			</div>
			<div class="seek">
				<input type="range" class="form-range" step="1" @change.prevent="change_time" v-model="current_time" min="0" :max="total_duration">
				<div class="time d-flex justify-content-between align-items-center">
					<small class="me-3">{{ $filters.human_readable_time(current_time) }}</small>
					<small class="ms-3">{{ $filters.human_readable_time(total_duration) }}</small>
				</div>
			</div>
			<div class="controls d-flex justify-content-evenly align-items-center space-x-3">
				<button class="btn border-0 h-100 text-white"><i class="i fa fa-fw fa-wave-square"></i></button>
				<div @click.prevent="previous" class="previous rounded-circle d-flex align-items-center" style="height: 40px; width: 40px;">
					<button class="btn border-0 w-100 h-100 text-white"><i class="fa fa-fw fa-step-backward"></i></button>
				</div>
				<div @click.prevent="play" class="play rounded-circle bg-white d-flex align-items-center" style="height: 45px; width: 45px;">
					<button v-if="playing" class="btn border-0 w-100 h-100 text-secondary"><i class="fa fa-fw fa-pause"></i></button>
					<button v-else class="btn border-0 w-100 h-100 text-secondary"><i class="fa fa-fw fa-play"></i></button>
				</div>
				<div @click.prevent="next" class="next rounded-circle d-flex align-items-center" style="height: 40px; width: 40px;">
					<button class="btn border-0 w-100 h-100 text-white"><i class="fa fa-fw fa-step-forward"></i></button>
				</div>
				<button @click.prevent="create_medley" class="btn border-0 h-100 text-white"><i class="i fa fa-fw fa-compact-disc"></i></button>
			</div>

			<div class="volume d-flex justify-content-between align-items-center">
				<button class="btn btn-sm text-white"> <i class="fa fa-fw fa-volume-down"></i> </button>
				<input type="range" class="form-range w-75" step="1" @change.prevent="change_volume" v-model="volume" min="0" max="100">
				<button class="btn btn-sm text-white"> <i class="fa fa-fw fa-volume-up"></i> </button>
			</div>

		</div>
	</Modal>
	<!-- Header -->
	<header id="page-header">
		<!-- Header Content -->
		<div class="content-header">
			<!-- Left Section -->
			<div class="d-flex align-items-center">
				<!-- Toggle Sidebar -->
				<!-- Layout API, functionality initialized in Template._uiApiLayout()-->
				<button type="button" class="btn btn-sm btn-alt-secondary me-2" data-toggle="layout" @click.prevent="toggle_sidebar" data-action="sidebar_toggle">
					<i class="fa fa-fw fa-bars"></i>
				</button>
				<!-- END Toggle Sidebar -->

				<!-- Toggle Mini Sidebar -->
				<!-- Layout API, functionality initialized in Template._uiApiLayout()-->
				<button type="button" class="btn btn-sm btn-alt-secondary me-2 d-none d-lg-inline-block" data-toggle="layout" @click.prevent="toggle_sidebar_mini" data-action="sidebar_mini_toggle">
					<i class="fa fa-fw fa-ellipsis-v"></i>
				</button>
				<!-- END Toggle Mini Sidebar -->

				<!-- Open Search Section (visible on smaller screens) -->
				<!-- Layout API, functionality initialized in Template._uiApiLayout() -->
				<button type="button" class="btn btn-sm btn-alt-secondary d-md-none" data-toggle="layout" data-action="header_search_on">
					<i class="fa fa-fw fa-search"></i>
				</button>
				<!-- END Open Search Section -->

				<!-- Search Form (visible on larger screens) -->
				<form class="d-none d-md-inline-block" method="POST">
					<div class="input-group input-group-sm">
						<input type="text" class="form-control form-control-alt" placeholder="Search.." id="page-header-search-input2" name="page-header-search-input2">
						<span class="input-group-text border-0">
							<i class="fa fa-fw fa-search"></i>
						</span>
					</div>
				</form>
				<!-- END Search Form -->

			</div>
			<!-- END Left Section -->

			<!-- Right Section -->
			<div class="d-flex align-items-center">

                <button @click.prevent="show_player" class="btn btn-sm btn-alt-secondary">
                    <i class="fa fa-fw fa-circle-play"></i> PLAYER
                </button>

                <!-- User Dropdown -->
                <div class="dropdown d-inline-block ms-2">
                    <button type="button" class="btn btn-sm btn-alt-secondary d-flex align-items-center" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <img class="rounded-circle" src="@/assets/media/avatars/avatar10.jpg" :alt="admin.name" style="width: 21px;">
                        <span class="d-none d-sm-inline-block ms-2">{{ admin.name }}</span>
                        <i class="fa fa-fw fa-angle-down d-none d-sm-inline-block opacity-50 ms-1 mt-1"></i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-md dropdown-menu-end p-0 border-0" aria-labelledby="page-header-user-dropdown">
                        <div class="p-3 text-center bg-body-light border-bottom rounded-top">
                            <img class="img-avatar img-avatar48 img-avatar-thumb" src="@/assets/media/avatars/avatar10.jpg" alt="">
                            <p class="mt-2 mb-0 fw-medium">{{ admin.name }}</p>
                            <p class="mb-0 text-muted fs-sm fw-medium">{{ admin.email }}</p>
                        </div>
                        <div class="p-2">
                            <router-link to="/" class="dropdown-item d-flex align-items-center justify-content-between">
                                <span class="fs-sm fw-medium">Settings</span>
                            </router-link>
                            <a class="dropdown-item d-flex align-items-center justify-content-between" href="">
                                <span class="fs-sm fw-medium">Lock Account</span>
                            </a>
                            <a @click.prevent="user_logout" class="dropdown-item d-flex align-items-center justify-content-between" href="">
                                <span class="fs-sm fw-medium">Log Out</span>
                            </a>
                        </div>
                    </div>
                </div>
                <!-- END User Dropdown -->
			</div>
			<!-- END Right Section -->
		</div>
		<!-- END Header Content -->

		<!-- Header Search -->
		<div id="page-header-search" class="overlay-header bg-body-extra-light">
			<div class="content-header">
				<form class="w-100" method="POST">
					<div class="input-group">
						<!-- Layout API, functionality initialized in Template._uiApiLayout() -->
						<button type="button" class="btn btn-alt-danger" data-toggle="layout" data-action="header_search_off">
							<i class="fa fa-fw fa-times-circle"></i>
						</button>
						<input type="text" class="form-control" placeholder="Search or hit ESC.." id="page-header-search-input" name="page-header-search-input">
					</div>
				</form>
			</div>
		</div>
		<!-- END Header Search -->

		<!-- Header Loader -->
		<!-- Please check out the Loaders page under Components category to see examples of showing/hiding it -->
		<div id="page-header-loader" class="overlay-header bg-body-extra-light">
			<div class="content-header">
				<div class="w-100 text-center">
					<i class="fa fa-fw fa-circle-notch fa-spin"></i>
				</div>
			</div>
		</div>
		<!-- END Header Loader -->
	</header>
	<!-- END Header -->
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Modal from "@/components/Modal.vue";
import {CURRENT_SONG} from "@/store/modules/player/getters";
import {CHANGE_VOLUME} from "@/store/modules/player/actions";

export default {
	name: "Main-Header",
	components: {Modal},
    data(){
        return{
            errors:[],
            admin:{
				name:null,
	            email:null
            },
			player_modal:null,
			playing: false,
			current_time: 0,
			total_duration: 0,
			percentage_played: 0,
			per_page: 80,
			songs: [],
			queue:[],
			current_song: {
				id: null,
				name: null,
				artist:{name:null}
			},
			current_index: null,
			volume:20,

        }
    },
	methods:{
		...mapActions('theme', ['TOGGLE_SIDEBAR_MINI', 'TOGGLE_SHOW_SIDEBAR', 'TOGGLE_SHOW_SIDE_OVERLAY']),
		...mapActions('auth', ['LOGOUT']),
        ...mapActions('admin', ['FETCH_ADMIN']),
		...mapActions('player', ['ADD_SONG', 'INIT_SONG', 'PLAY_SONG', 'PLAY', 'PAUSE', 'STOP', 'NEXT', 'PREVIOUS', 'CHANGE_TIME', 'INIT_PLAYER', 'CHANGE_VOLUME']),
		...mapActions('medley', ['CREATE_MEDLEY']),
		show_player(){
			this.player_modal = new bootstrap.Modal('#player_modal')
			this.player_modal.show()
		},
		user_logout(){
			this.LOGOUT().then(() => {
                localStorage.removeItem(process.env.VUE_APP_ACCESS_TOKEN_KEY);
                this.$router.push('/auth/login')
            });
		},
		toggle_sidebar_mini(){
			this.TOGGLE_SIDEBAR_MINI()
		},
		toggle_side_overlay(){
			this.TOGGLE_SHOW_SIDE_OVERLAY()
		},
		toggle_sidebar(){
			this.TOGGLE_SHOW_SIDEBAR()
		},
		create_medley(){

			let playlist = {
				'queue':[]
			}

			this.queue.forEach( (queue, index) => {
				playlist.queue[index] = queue.id
			})

			this.CREATE_MEDLEY({
				form_data: playlist
			}).then( response =>{

			}).catch(()=>{})
		},
		play() {
			if (this.playing) {
				this.PAUSE()
			} else {
				this.PLAY()
			}
		},
		stop() {
			this.STOP()
		},
		previous() {
			this.PREVIOUS()
		},
		next() {
			this.NEXT()
		},
		change_time() {
			this.CHANGE_TIME(this.current_time)
		},
		change_volume(){
			this.CHANGE_VOLUME(this.volume)
		}

	},
    mounted() {
		this.INIT_PLAYER()
    },
    computed: {
        ...mapGetters('admin',['ADMIN']),
		...mapGetters('player', ['QUEUE', 'CURRENT_INDEX', 'PLAYING', 'TOTAL_DURATION', 'PERCENTAGE_PLAYED', 'CURRENT_TIME', 'CURRENT_SONG', 'QUEUE', 'VOLUME'])
    },
    watch: {
        ADMIN(){
            this.admin = this.ADMIN
        },
		CURRENT_SONG() {
			this.current_song = this.CURRENT_SONG
		},
		PLAYING() {
			this.playing = this.PLAYING
		},
		TOTAL_DURATION() {
			this.total_duration = this.TOTAL_DURATION
		},
		PERCENTAGE_PLAYED() {
			this.percentage_played = this.PERCENTAGE_PLAYED
		},
		CURRENT_TIME() {
			this.current_time = this.CURRENT_TIME
		},
		CURRENT_INDEX() {
			this.current_index = this.CURRENT_INDEX
		},
		QUEUE(){
			this.queue = this.QUEUE
		},
		VOLUME(){
			this.volume = this.VOLUME
		}
    }
}
</script>

<style scoped>

</style>
